$row-padding: 2px;
$hover-padding: 3px;
.rowWrapper {
  height: 100%;
  box-sizing: border-box;
  cursor: move;

  &:active {
    opacity: 1;
  }
}

.rowWrapperDragDisabled {
  cursor: default;
  //&:hover {
  //  opacity: 1;
  //}
}

.row {
  height: 100%;
  white-space: nowrap;
  display: flex;

  & > * {
    box-sizing: border-box;
  }
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rowLandingPad {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  width: 500px;
  * {
    opacity: 0 !important;
  }

  &::before {
    background-color: #E5E5E5;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rowCancelPad {
  @extend .rowLandingPad;

  &::before {
    background-color: #e6a8ad;
  }
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rowSearchMatch {
  outline: solid 1px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rowSearchFocus {
  outline: solid 1px #fc6421;
}

%rowItem {
  display: inline-block;
  vertical-align: middle;
}

.rowContents {
  transition: all .3s;

  @extend %rowItem;
  position: relative;
  height: 100%;
  padding-right: 5px;
  max-width: 100%;
  min-width: 230px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  overflow: hidden;

  &:hover {
    background: #E5E5E5;
  }
}

.rowLabel {
  @extend %rowItem;
  flex: 0 1 auto;
  padding-right: 20px;
  padding-left: 10px;
  width: 90%;
  display: flex;
}

.rowToolbar {
  @extend %rowItem;
  flex: 0 1 auto;
  display: flex;
  margin-right: 10px;
}

.moveHandle {
  @extend %rowItem;

  height: 100%;
  width: 44px;
  background: #d9d9d9
  url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
  no-repeat center;
  border: solid #aaa 1px;
  box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
}

.loadingHandle {
  @extend .moveHandle;

  cursor: default;
  background: #d9d9d9;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  $point-count: 12;
  $spin-animation-time: 800ms;

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 11%;
    height: 30%;
    background-color: #fff;
    border-radius: 30%;
    animation: pointFade $spin-animation-time infinite ease-in-out both;
  }

  @for $i from 1 through (($point-count + 1) / 2) {
    &:nth-of-type(#{$i}) {
      transform: rotate(360deg / $point-count * ($i - 1));
    }

    &:nth-of-type(#{$i + $point-count / 2}) {
      transform: rotate(180deg + 360deg / $point-count * ($i - 1));
    }

    &:nth-of-type(#{$i}),
    &:nth-of-type(#{$i + $point-count / 2}) {
      &:before {
        animation-delay: - $spin-animation-time + ($spin-animation-time /
              $point-count * 2 * ($i - 1));
      }
    }
  }
}

.toolbarButton {
  @extend %rowItem;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.6
  }
}

.rowTitle {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rowTitleWithSubtitle {
  display: block;
}

.rowSubtitle {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.7);
  margin: 4px 0 0 0;
}

.buttonWrapper {
  display: flex;
  align-items: center;
}

.collapseButton {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
  margin: 3px 8px 0 0;

  &:focus {outline:0;}
}

.Indicators {
  position: absolute;
  display: flex;
  height: calc(100% + 2px);

  &__Item {
    width: 5px;
    height: 100%;
  }
}

//.collapseButton {
//  &:before {
//    content: "\25bc";
//  }
//}
//
//.expandButton {
//  &:before {
//    content:"\25b6";
//  }
//}

/**
 * Line for under a node with children
 */
.lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
}

.contentContainer {
  width: 75%;
}

.LoadMore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer !important;

  &:hover {
    transition: 0.3s;
    color: rgb(0, 168, 78);
  }
}