.Container {
  margin-bottom: 30px; }

.Actions {
  display: flex;
  justify-content: flex-end;

  &__Item {
    margin-right: 16px;
    color: #008F42 !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    cursor: pointer;

    &:hover {
      opacity: 0.8; }

    &:last-child {
      margin-right: 0; }

    &_remove {
      color: #C62828 !important; }

    &:disabled {
      color: rgba(0, 0, 0, 0.26) !important; } } }


.Title {
  color: #9E9E9E !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  padding-top: 10px !important;
  padding-bottom: 10px !important; }

.MainTitle {
  font-size: 24px;
  line-height: 36px; }

.SearchBlock {
  margin: 0 0 16px; }

.Back {
  display: flex;
  align-items: center;
  margin-top: 22px;
  text-decoration: none;
  letter-spacing: 0.25px;
  font-size: 14px;
  line-height: 21px;
  color: #00A84E;

  &__Text {
    margin-left: 6px; } }

.Checkboxes {
  display: grid !important;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px; }

.Content {
  padding: 15px 18px; }

.TriangleIcon {
  margin-left: 5px;
  fill: #00B956; }

.Header {
  margin-bottom: 15px;

  &__Checkbox {
    margin-left: -1px !important;
    padding: 0 !important; } }

.AddButton {
  background: #00B956 !important;
  color: #fff !important;
  padding: 10px 16px !important;
  letter-spacing: 1.25px !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;

  &:before {
    content: '+';
    margin-right: 11px;
    font-size: 20px; }

  &_submit {
    &:before {
      content: '';
      margin-right: 0; } } }

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;

  &__Container {
    position: absolute;
    width: 768px;
    max-height: 90vh;
    overflow-y: auto;
    background: #fff;
    box-shadow: 0 15px 12px rgba(0, 0, 0, 0.22), 0 19px 38px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    padding: 30px 29px 35px; }

  &__Title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px; }

  &__Items {
    display: flex;
    flex-direction: column; }

  &__Item {
    margin-top: 50px;

    &:first-child {
      margin-top: 0; } }

  &__Submit {
    margin-top: 20px; }

  &__ChangeName {
    display: grid;
    align-items: center;
    grid-template-columns: 105px 1fr;
    margin-top: 10px; } }

.Pagination {
  margin-top: 30px;
  margin-bottom: 10px; }
