.UploadPublications {
  &_root {
    box-sizing: border-box;
    padding: 0 20px 0 0;
    display: flex;
    align-items: center;
    position: relative;
  }

  &_panel {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    box-sizing: border-box;
    padding: 20px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.14)
  }

  &_modal {
    &Content {
      background: #FFFFFF;
      display: flex;
      width: fit-content;
      border-radius: 16px;
      margin: 20% auto 0 ;
      box-sizing: border-box;
      padding: 20px 40px;

      &Item {
        margin: 0 12px;
        cursor: pointer;
      }

      &ItemPicker {

      }
    }
  }
}
