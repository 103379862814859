@import '~@styles/palette.scss';

:global html {
  box-sizing: border-box;
}

:global body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #1D1D1B;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global * {
  box-shadow: none;
  box-sizing: inherit;

  &::after,
  &::before {
    box-sizing: inherit;
  }

  &:focus {
    outline: none;
  }
}

:global h1,
:global h2,
:global h3,
:global h4,
:global h5,
:global h6 {
  margin: 0;
}

:global .SplitPane.vertical {
  position: relative !important;
  overflow: visible !important;
}

:global .SplitPane.vertical div:nth-child(3) {
  overflow: hidden;
}

:global .Resizer {
  background: #000;
  z-index: 1;
}

:global .Resizer.vertical {

  width: 1px;
  background: #E5E5E5;
  cursor: col-resize;
}
