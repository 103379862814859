.MuiPickersDay-daySelected {
  background-color: #00B956 !important;
}

.MuiInput-underline:before, .MuiInput-underline:after {
  border: none !important;
}

.MuiInput-underline {
  color: #00B956 !important;
}

.MuiInput-underline .MuiButtonBase-root, .MuiRadio-root, .MuiCheckbox-root {
  padding: 5px !important;
}

.MuiIconButton-colorSecondary {
  color: #008F42 !important;
}

.MuiFormControlLabel-root {
  margin: 0 !important;
}

.rmsc {
  --rmsc-main: rgb(0, 168, 78) !important;
  --rmsc-selected: rgba(255, 255, 255, 0.6) !important;
}

.MuiInputBase-root {
  height: 100%;
}

.tox.tox-tinymce {
  flex: 1;
}

#tinymce {
  padding: 40px;
}

.dxc-hole-template text {
  font-size: 18px !important;
}