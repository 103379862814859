$color-white-1000: #FFFFFF;

$color-black-1000: #000000;

$color-purple-500: #403294;
$color-purple-400: #5243aa;
$color-purple-300: #6554c0;
$color-purple-200: #8777d9;
$color-purple-100: #998dd9;
$color-purple-75: #c0b6f2;
$color-purple-50: #eae6ff;
